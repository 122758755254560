<template>
  <!--      Start  Form -->
  <v-dialog
    v-if="entity"
    v-model="open"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    persistent
    scrollable
  >
    <v-card>
      <v-toolbar
        flat
        class="pr-5"
      >
        <v-btn
          icon
          :loading="processing"
          :disabled="processing"
          @click="close"
        >
          <v-icon>mdi-keyboard-backspace</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("model.user.user") +' ('+(!editMode ?  $t('base.addNew') : $t('base.edit'))+')'}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- <v-btn
          text
          @click="close"
          :disabled="processing"
        >
          {{ $t("base.cancel") }}
        </v-btn> -->
        <!-- <v-btn
          text
          @click="onSave"
          :disabled="processing"
        >
          <v-icon>mdi-lead-pencil</v-icon> {{ $t("base.save") }}
        </v-btn> -->
      </v-toolbar>
      <v-card-text>
        <validation-observer
          ref="form"
          v-slot="{ handleSubmit, reset }"
        >
          <form
            @submit.prevent="handleSubmit(onSave)"
            @reset.prevent="reset"
          >
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('model.user.username')"
                    rules="required|min:4|max:255"
                  >
                    <v-text-field
                      prepend-icon="mdi-account"
                      v-model="entity.username"
                      :error-messages="errors"
                      :placeholder="$t('model.user.username')"
                      :label="$t('model.user.username')"
                    ></v-text-field>
                  </validation-provider>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('model.user.email')"
                    rules="required|email|max:255"
                  >
                    <v-text-field
                      prepend-icon="mdi-email"
                      v-model="entity.email"
                      :error-messages="errors"
                      :placeholder="$t('model.user.email')"
                      :label="$t('model.user.email')"
                    ></v-text-field>
                  </validation-provider>

                </v-col>

                <template v-if="!editMode">

                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="password"
                      rules="required|min:8|password:@confirm"
                    >
                      <v-text-field
                        prepend-icon="mdi-shield-key"
                        v-model="entity.password"
                        :error-messages="errors"
                        :placeholder="$t('authen.password')"
                        :label="$t('authen.password')"
                        :append-icon="
                        showPwd
                          ? 'mdi-eye-outline'
                          : 'mdi-eye-off-outline'
                      "
                        :type="showPwd ? 'text' : 'password'"
                        @click:append="showPwd = !showPwd"
                        autocomplete="off"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="confirm"
                      rules="required|min:8|password:@password"
                    >
                      <v-text-field
                        prepend-icon="mdi-shield-key"
                        :append-icon="
                        showPwd
                          ? 'mdi-eye-outline'
                          : 'mdi-eye-off-outline'
                      "
                        :type="showPwd ? 'text' : 'password'"
                        v-model="entity.repassword"
                        :error-messages="errors"
                        @click:append="showPwd = !showPwd"
                        :placeholder="$t('authen.confirmPassword')"
                        :label="$t('authen.confirmPassword')"
                        autocomplete="off"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </template>
                <template v-else>
                  <v-col
                    cols="12"
                    key="user-form-onResetUserPassword"
                  >
                    <v-btn
                      color="error"
                      @click="$emit('reset-pwd')"
                      :loading="processing"
                      :disabled="processing"
                    >
                      <v-icon class="mr-2">mdi-shield-key</v-icon>{{$t('authen.resetPassword')}}
                    </v-btn>
                  </v-col>
                </template>

                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-switch
                    v-model="entity.status"
                    :label="
                      entity.status ? $t('base.enable') : $t('base.disable')
                    "
                  ></v-switch>
                </v-col>

                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-row>
                    <v-col>
                      <v-card>
                        <v-toolbar
                          color="primary"
                          dark
                          flat
                        >
                          <v-icon>mdi-account-group</v-icon>
                          <v-toolbar-title class="ml-3">{{ $t("model.role.role") }}</v-toolbar-title>
                        </v-toolbar>

                        <v-row>
                          <v-col>

                            <v-text-field
                              v-model="searchTxt"
                              :label="$t('base.search')"
                              filled
                              rounded
                              dense
                              hide-details
                              clearable
                              clear-icon="mdi-close-circle"
                            ></v-text-field>

                            <v-card-text
                              style="max-height: 250px"
                              class="overflow-y-auto"
                            >
                              <v-treeview
                                v-model="entity.userRoles"
                                :items="roles"
                                :search="searchTxt"
                                :filter="filter"
                                item-key="id"
                                item-text="description"
                                :selection-type="'leaf'"
                                selectable
                                :return-object="false"
                                open-all
                                hoverable
                              ></v-treeview>
                            </v-card-text>
                          </v-col>

                          <v-divider vertical></v-divider>

                          <v-col
                            cols="12"
                            md="6"
                          >
                            <v-card-text
                              style="max-height: 250px"
                              class="overflow-y-auto"
                            >
                              <div
                                v-if="entity.userRoles.length === 0"
                                key="title"
                                class="title font-weight-light grey--text pa-4 text-center"
                              >
                                Select roles for this user.
                              </div>

                              <v-scroll-x-transition
                                group
                                hide-on-leave
                              >
                                <v-chip
                                  class="ma-2"
                                  close
                                  v-for="(r,index) in entity.userRoles"
                                  :key="`role-form-permission-selected-${index}`"
                                  @click:close="removeRoles(index)"
                                >
                                  {{ findDisplayById(r) }}
                                </v-chip>
                              </v-scroll-x-transition>
                            </v-card-text>
                          </v-col>
                        </v-row>

                        <v-divider></v-divider>

                        <v-card-actions>

                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            @click="entity.userRoles = []"
                          >
                            <v-icon>mdi-delete</v-icon>
                            {{$t('base.clear')}}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col
                  cols="12"
                  class="mt-6"
                  align="center"
                >
                  <v-btn
                    text
                    @click="close"
                    :disabled="processing"
                  >
                    {{ $t("base.cancel") }}
                  </v-btn>
                  <v-btn
                    type="submit"
                    color="primary"
                    text
                    :disabled="processing"
                  >
                    <v-icon>mdi-lead-pencil</v-icon> {{ $t("base.save") }}
                  </v-btn>
                </v-col>

              </v-row>
            </v-container>

          </form>
        </validation-observer>
      </v-card-text>
      <base-wee-loader v-model="processing" />
    </v-card>
  </v-dialog>
</template>
<script>
import {
  //isEmpty,
  hashSha512
} from "@/plugins/util";
import { defineComponent, reactive, ref, computed } from "@vue/composition-api";
export default defineComponent({
  props: {
    value: null,
    open: {
      type: Boolean,
      default: false
    },
    editMode: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    },
    roles: {
      type: Array,
      default: []
    }
  },
  setup(props, { emit }) {
    const entity = reactive(props.value);
    const showPwd = ref(false);
    const close = () => {
      emit("close");
    };
    const onSave = async () => {
      if (!props.editMode) {
        entity.password = await hashSha512(entity.password);
        entity.repassword = entity.password;
      }

      emit("save", entity);
    };

    //additional
    const searchTxt = ref("");
    const findDisplayById = id => {
      const item = props.roles.find(c => c.id == id);
      return item ? (item.description ? item.description : item.name) : "";
    };

    const caseSensitive = ref(false);
    const filter = computed(() => {
      return caseSensitive.value
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined;
    });

    // const editMode = computed(() => !isEmpty(entity.id));

    const removeRoles = index => {
      entity.userRoles.splice(index, 1);
    };

    return {
      showPwd,
      entity,
      close,
      onSave,
      //role
      searchTxt,
      findDisplayById,
      filter,
      removeRoles
    };
  }
});
</script>
